.add-event-btn {
  margin-top: 80px;
  margin-bottom: 50px;
  border: none;
  background-color: blue;
  color: white;
  width: auto;
  height: 40px;
  border-radius: 10px;
}

.pb-1 {
  margin-top: 2vh;
  font-weight: bold;
}

.event-title {
  margin-top: 5vh;
  margin-bottom: 5vh;
  text-align: left;
}

.small-icon {
  margin-right: 5px;
}

.tag {
  /* background-color: blue; */
  text-align: center;
  /* color: white; */
}

.serach-box {
  border: none;
  background-color: whitesmoke;
  /* height: 20px; */
  /* border: 0.5px solid black; */
}

.tags {
  text-align: start;
}

.add-new {
  margin-top: 2vh;
  margin-right: 80%;
}
