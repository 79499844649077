.login-headding {
  text-align: center;
}
.eye-icon {
  float: right;
  margin-left: -25px;
  margin-top: 10px;
  position: relative;
  z-index: 2;
  margin-right: 10px;
  border-bottom-right-radius: 10px;
}
.profile-container {
  margin: 15vh auto;
}
#signIn {
  background-color: blue;
  cursor: pointer;
  color: white;
  width: 30%;
  margin-left: 30%;
}
.login-inner-container {
  margin: auto;
}
.nav-link {
  cursor: pointer;
}
