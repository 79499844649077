.event-type {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.offline-radiobtn {
  /* margin-top: 100px; */
  margin-left: 400px;
  border: 1px solid red;
}

.intel-dropdown {
  background-color: #fff;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 6px;
  outline: 0;
}

.icon-60-size {
  font-size: 60px !important;
  color: #007bff;
}

.student-image {
  height: 30vh;
}

.back-button {
  text-align: right;
  margin-bottom: 2vh;
}

.student-profile {
  margin: 5vh 0vh;
  background-color: #fff;
  padding-top: 5vh;
  padding-left: 7vh !important;
  border-radius: 10px;
}

.dashboard-row {
  padding-top: 5vh;
}

.student-data {
  color: #4169e1;
  font-size: 20px;
  font-weight: 600;
}

.list-back {
  text-align: right;
  margin-top: 3vh;
}

.intel-option {
  background-color: #fff;
  padding: 8px 16px !important;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
}

.close-button {
  background-color: #ff0000 !important;
  margin-top: 10px;
  margin-right: 20px;
}

.add-button {
  margin-top: 10px;
  margin-right: 20px;
}

.online-radiobtn {
  margin-left: 100px;
}

.radio-btn-content {
  margin-left: 50px;
}

#online {
  margin-right: 30px;
}

#offline {
  margin-right: 30px;
}

.submit-btn {
  margin-top: 50px;
  border: none;
  width: 100px;
  height: 40px;
  background-color: blue;
  color: white;
  border-radius: 10px;
}

.pdf-download-btn {
  margin-left: -120px;
  margin-right: 30px;
}

.pdf-down-btn {
  margin-right: 100px;
}

.w-200 {
  width: 200px;
}

.error-section {
  background: #FFEEED;
  border: 1px solid red;
  border-radius: 0.625rem;
}
